<template>
  <div class="history-analysis">
    <div class="history-analysis-left">
      <div class="device-tree">
        <div class="device-tree-header">
          <a-tabs v-model:activeKey="activeKey" centered @change="handleTabChange">
            <a-tab-pane :key="1" tab="分组"></a-tab-pane>
            <a-tab-pane :key="2" tab="产品"></a-tab-pane>
          </a-tabs>
        </div>
        <div class="device-tree-content">
          <div
            style="text-align: center; padding: 50px 0 0"
            v-if="deviceTreeLoading"
          >
            <a-spin :spinning="deviceTreeLoading" tip="加载中..."> </a-spin>
          </div>
          <a-tree
            v-else-if="deviceTree.length"
            :selectable="false"
            checkable
            :fieldNames="{
              title: 'name',
              key: 'id',
            }"
            :defaultExpandedKeys="defaultExpandedKeys"
            :checkedKeys="checkedDevices"
            :tree-data="deviceTree"
            :load-data="onLoadData"
            @check="checkDeviceTree"
          >
          </a-tree>
          <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" />
        </div>
      </div>
    </div>
    <div class="history-analysis-right">
      <div class="history-analysis-search">
        <a-form layout="inline">
          <a-form-item>
            <a-select
              style="width: auto; min-width: 120px"
              placeholder="选择采样周期"
              allowClear
              v-model:value="searchModel.periodType"
              :options="periodOptions"
              @change="changePeriod"
            >
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker
              style="width: 330px"
              :value="searchModel.times"
              :disabledDate="disabledDate"
              :show-time="{
                defaultValue: [
                  dayjs('00:00:00', 'HH:mm:ss'),
                  dayjs('23:59:59', 'HH:mm:ss'),
                ],
              }"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              @change="onChangeTimes"
              @openChange="onOpenChange"
              @calendarChange="onCalendarChange"
            />
          </a-form-item>
          <a-form-item>
            <a-select
              style="width: auto; min-width: 120px"
              placeholder="选择属性"
              mode="multiple"
              :max-tag-count="1"
              :value="searchModel.attrCode"
              :options="attrOptions"
              :field-names="{ label: 'attrName', value: 'attrCode' }"
              @change="changeAttr"
            >
            </a-select>
          </a-form-item>
        </a-form>
        <div class="history-analysis-search-actions">
          <a-button type="primary" :loading="searchLoading" @click="onSearch">
            查询
          </a-button>
          <a-button :loading="exportLoading" @click="onExport">导出</a-button>
        </div>
      </div>
      <div class="history-analysis-content">
        <a-spin v-if="searchLoading" :spinning="searchLoading" tip="加载中...">
        </a-spin>
        <template v-else>
          <BaseChart
            v-if="chartOptions"
            width="100%"
            height="100%"
            :options="chartOptions"
          ></BaseChart>
          <a-empty v-else />
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, toRaw } from "vue";
import { Empty, message, Form } from "ant-design-vue";
import { apiHistory } from "@/api/history";
import dayjs from "dayjs";
import _ from "lodash";
import { downLoadFile } from "@/utils/excel";
const useForm = Form.useForm;

const activeKey = ref(1);

const checkedDevices = ref([]);
const deviceTreeLoading = ref(false);
const deviceTree = ref([]);

const defaultExpandedKeys = ref([])
const initDeviceTree = async (api) => {
  deviceTreeLoading.value = true;
  try {
    const { result } = await apiHistory[api]();
    deviceTree.value = [resetTreeData(result)];
    defaultExpandedKeys.value = [result.id]
  } catch (error) {
    console.log(error);
  }
  deviceTreeLoading.value = false;
};

const onLoadData = async (treeNode)=>{
  if(treeNode.dataRef.children){
    return
  }
  if(!treeNode.projectBizId){
    return
  }
  try {
    const { result } = await apiHistory[`${activeKey.value == 1?'deviceTreeSpace':'deviceTreeProduct'}`]({
      bizProjId: treeNode.projectBizId
    });
    treeNode.dataRef.children = resetTreeData(result).children;
    deviceTree.value = [...deviceTree.value]
   // deviceTree.value = [resetTreeData(result)];
  } catch (error) {
    console.log(error);
  }
  // deviceTreeLoading.value = false;
}

const resetTreeData = (source)=>{
  let copyData = _.cloneDeep(source);
  function traver(data){
    if(Array.isArray(data.children) && data.children.length>0){
      data.children.forEach(child=>{
        child.id = `${child.id}@@${data.id&&data.id.split('@@')[0]}`
        traver(child)
      })
    }else{
      if(!data.projectBizId){
        data.isLeaf = true;
      }
    }
  }
  traver(copyData);
  return copyData;
}

initDeviceTree('deviceTreeSpace');
const handleTabChange = (val)=>{
  if(val == 1){
    //分组
    initDeviceTree('deviceTreeSpace')
  }else if(val == 2){
    //产品
    initDeviceTree('deviceTreeProduct')
  }
}
const checkDeviceTree = (checkedKeys, e) => {
  const { checkedNodes, node } = e;
  const { bizProductId } = node?.dataRef ?? {};
  const checkedKeysLength = checkedKeys?.length ?? null;
  const currentBizProductId = checkedNodes?.length
    ? checkedNodes[0].bizProductId
    : null;
  if (
    !!checkedKeysLength &&
    currentBizProductId &&
    currentBizProductId !== bizProductId
  ) {
    message.warning("您要选的设备和已选设备不属于同一产品，请重新选择");
    return;
  }
  if (checkedKeys.length > 5) {
    message.warning("最多勾选5项");
    return;
  }
  checkedDevices.value = checkedKeys;
  searchModel.attrCode = [];
  attrOptions.value = checkedNodes[0]?.attrCodes || [];
};

// 搜索栏
const searchModel = reactive({
  periodType: null,
  times: [],
  attrCode: [],
});
const { validate, validateInfos } = useForm(
  searchModel,
  reactive({
    periodType: [
      {
        required: true,
      },
    ],
    times: [
      {
        required: true,
      },
    ],
    attrCode: [
      {
        required: true,
      },
    ],
  })
);
const getApiParams = async () => {
  return new Promise((resolve, reject) => {
    if (!checkedDevices.value.join(",")) {
      reject("未选择设备");
      message.warning("请选择设备");
      return;
    }
    validate()
      .then(() => {
        const { periodType, times, attrCode } = toRaw(searchModel);
        let ids = checkedDevices.value&&checkedDevices.value.map(item=>item.split('@@')[0])
        const params = {
          bizDeviceIds: ids.join(","),
          periodType,
          times,
          attrCode: attrCode.join(","),
        };
        resolve(params);
      })
      .catch(() => {
        reject("输入项未完成");
        const { periodType, times, attrCode } = validateInfos;
        if (periodType.validateStatus === "error") {
          message.warning("请选择采样周期");
          return;
        }
        if (times.validateStatus === "error") {
          message.warning("请选择时间范围");
          return;
        }
        if (attrCode.validateStatus === "error") {
          message.warning("请选择属性");
          return;
        }
      });
  });
};
// 采样周期
const periodOptions = [
  {
    label: "原始",
    value: 0,
    dayDiffType: "week",
  },
  {
    label: "5分钟",
    value: 1,
    dayDiffType: "month",
  },
  {
    label: "10分钟",
    value: 2,
    dayDiffType: "month",
  },
  {
    label: "30分钟",
    value: 3,
    dayDiffType: "month",
  },
  {
    label: "1小时",
    value: 4,
    dayDiffType: "year",
  },
  {
    label: "8小时",
    value: 5,
    dayDiffType: "year",
  },
  {
    label: "1天",
    value: 6,
    dayDiffType: "year",
  },
];
let currentDayDiffType = null;
const changePeriod = (value, option) => {
  searchModel.times = [];
  dates = [];
  currentDayDiffType = option?.dayDiffType ?? null;
};
// 日期范围
let dates = [];
const disabledDate = (current) => {
  if (!dates || dates.length === 0 || !currentDayDiffType) {
    return false;
  }
  const tooLate =
    dates[0] && dayjs(current).diff(dates[0], currentDayDiffType) >= 1;
  const tooEarly =
    dates[1] && dayjs(dates[1]).diff(current, currentDayDiffType) >= 1;
  return tooEarly || tooLate;
};
const onCalendarChange = (val) => {
  dates = val;
};
const onOpenChange = (open) => {
  if (open) {
    dates = [];
  }
};
const onChangeTimes = (val) => {
  searchModel.times = val;
};
// 属性
const attrOptions = ref([]);
const selectedAttrs = ref([]);
const changeAttr = (value, option) => {
  if (value?.length > 2) {
    message.warning("最多选择两个属性");
    return;
  }
  searchModel.attrCode = value;
  selectedAttrs.value = option;
};
// 查询
const CHART_OPTIONS = {
  tooltip: {
    trigger: "axis",
  },
  legend: {
    show: false,
  },
  xAxis: {
    type: "time",
    boundaryGap: false,
    axisLine: {
      lineStyle: {
        color: "#D9D9D9",
      },
    },
    axisTick: {
      lineStyle: {
        color: "#D9D9D9",
      },
    },
    axisLabel: {
      color: "color: rgba(0, 0, 0, 0.65);",
      formatter: function (v) {
        return dayjs(v).format("YYYY-MM-DD HH:mm:ss").replace(" ", "\n");
      },
    },
  },
  grid: {
    left: "5%",
    right: "5%",
    bottom: "2%",
    containLabel: true,
  },
  yAxis: [],
  series: [],
};
const chartOptions = ref(null);
const searchLoading = ref(false);
const onSearch = async () => {
  searchLoading.value = true;
  try {
    const params = await getApiParams();
    const { result } = await apiHistory.list(params);
    const _options = _.cloneDeep(CHART_OPTIONS);
    _options.yAxis = selectedAttrs.value.map((item, index) => ({
      name: `${item.attrName}（${item.unit}）`,
      nameTextStyle: {
        align: index === 0 ? "left" : "right",
      },
      yAxisIndex: index,
      attrCode: item.attrCode,
      type: "value",
      splitLine: {
        lineStyle: {
          color: "#E8E8E8",
          type: "dashed",
        },
      },
      axisLabel: {
        color: "color: rgba(0, 0, 0, 0.65);",
      },
    }));
    _options.series = result
      .map((item) => {
        const { attrCode, attrName, deviceName, times, values } = item;
        const isFindItem = _options.yAxis.find(
          (item) => item.attrCode === attrCode
        );
        if (isFindItem) {
          let data = [];
          times.forEach((item, index) => {
            let _item = [];
            _item[0] = dayjs(item).format();
            _item[1] = values[index];
            data.push(_item);
          });
          // Object.keys(valueMap)
          //   .sort()
          //   .forEach((item) => {
          //     let _item = [item, valueMap[item]];
          //     data.push(_item);
          //   });
          // data = data.map((item) => {
          //   let _item = item;
          //   _item[0] = _item[0].replace(" ", "\n");
          //   return _item;
          // });
          return {
            name: `${deviceName}-${attrName}`,
            type: "line",
            yAxisIndex: isFindItem.yAxisIndex,
            smooth: true,
            showSymbol: false,
            data,
          };
        }
      })
      .filter((item) => item);
    chartOptions.value = _options;
  } catch (error) {
    chartOptions.value = null;
    console.log(error);
  }
  searchLoading.value = false;
};
// 导出
const exportLoading = ref(false);
const onExport = async () => {
  exportLoading.value = true;
  try {
    const params = await getApiParams();
    const res = await apiHistory.export(params);
    const { filename, blob } = res;
    downLoadFile(blob, decodeURI(filename));
    message.success("导出成功");
  } catch (error) {
    console.log(error);
  }
  exportLoading.value = false;
};
</script>
<style lang="less" scoped>
.history-analysis {
  display: flex;
  gap: 8px;
  height: 100%;
  &-left {
    width: 260px;
    background: #fff;
    .device-tree {
      display: flex;
      flex-direction: column;
      height: 100%;
      &-header {
        ::v-deep .ant-tabs-nav {
          margin: 0;
        }
        ::v-deep .ant-tabs-tab {
          font-size: 16px;
          line-height: 1;
          padding: 16px 0;
        }
      }
      &-content {
        flex: 1;
        width: 260px;
        padding: 12px 6px;
        overflow: auto;
      }
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    min-width: 850px;
  }
  &-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    &-actions {
      display: flex;
      gap: 8px;
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 4px;
    border: 1px solid #e6e8eb;
    margin: 8px 20px 20px;
  }
}
</style>
