import http from "../utils/http";

// 应用菜单接口 
export const apiHistory = {
  deviceTree() {
    return http.request({
      url: "/cemp-monitor/device-monitor/device-tree",
      method: "get",
    });
  },
  deviceTreeSpace(params) {
    return http.request({
      url: "/cemp-monitor/device-monitor/device-tree/v1/space",
      method: "get",
      params
    });
  },
  deviceTreeProduct(params) {
    return http.request({
      url: "/cemp-monitor/device-monitor/device-tree/v1/product",
      method: "get",
      params
    });
  },
  list(data) {
    return http.request({
      url: "/cemp-monitor/data/history/list",
      method: "post",
      data,
    });
  },
  export(data) {
    return http.request({
      url: "/cemp-monitor/data/history/export",
      method: "post",
      responseType: "blob",
      data,
    });
  },
};
